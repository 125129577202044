<template>
    <diV>
        <v-card class="container">
            <div class="row col">
                <v-row>
                    <v-col cols="3">
                        <h1 class="h3 mt-2">Armar Palet</h1>
                    </v-col>
                    <v-col cols="12" >
                        <!-- <s-select 
                            multiple
                            selected
                            :items="ItemsExitCategories"
                            label="Tipo de Congelamiento"                                
                            item-value="CecID"                                
                            item-text="CecDescription"
                            v-model="CecID"
                            @input="TipoCongelamiento()">
                        </s-select> -->
                        <v-row>
                            <v-col cols="auto">
                                <label><strong>Tipo de Congelamiento</strong></label>
                            </v-col>
                            <template v-for="(item) in ItemsExitCategories">
                                <v-col cols="auto" :key="item.CecDescription">
                                    <v-checkbox @change="TipoCongelamiento()"  style="margin:0px;padding:0px;" v-model="CecID" :value="item.CecID" :label="item.CecDescription"></v-checkbox>
                                </v-col>
                            </template>
                        </v-row>
                        
                    </v-col>      
                </v-row>
            </div>
            <div class="row">
                <div class="col">
                    <s-toolbar
                        label="Cajas ingresadas"
                        color="#E0F7F2">
                        <s-select label="Campaña" class="mb-4" 
                            :items="CampaignArea"
                            item-value="CmaID"
                            item-text="CmaName"
                            v-model="SelectCampaign">

                        </s-select>
                    </s-toolbar>
                
                    <v-data-table
                        :headers="headersPalet" 
                        :items="itemspalletsBox"
                        :items-per-page="-1"
                        show-select 
                        item-key="Line"
                        dense
                        v-model="selectItemsBox">
                        <template v-slot:item.TotalBox="{ item }">
                            <s-text v-model="item.TotalBox" type="number" :disabled="item.disabled == 1"  @input="UpdateWeight(item)" >

                            </s-text>
                        </template>
                    </v-data-table>
                </div>
            </div>
            <div class="row">                         
                <div class="col">
                    <v-card class="col" color="warning">
                        <v-card-text>
                            <s-toolbar label="Seleccionados" color="#E0F7F2" >                            
                                {{ 'N° Cajas ' + NumberBoxes + '   Peso Neto ' + WeightNetBoxes }}
                            </s-toolbar>  
                            <v-row>
                                <v-col cols="auto">
                                    <v-data-table  
                                        :headers="headersBoxSelected"
                                        :items="ArmedItemsBox"
                                        item-key="Index">
                                            <template v-slot:item.remove="{ item }">
                                                <v-btn
                                                    @click="clickDelete(item)"
                                                    x-small
                                                    fab
                                                  
                                                    color="error" >
                                                    <v-icon style="font-size: 16px !important"
                                                        >mdi-close</v-icon >
                                                </v-btn>
                                            </template>                                
                                    </v-data-table>
                                </v-col>
                                <v-col>
                                    <v-row>
                                        <v-col>                                 
                                            <s-select-client hidden
                                                clearable
                                                label="Cliente"
                                                v-model="CumID"
                                                ref="txtCumID"
                                                full
                                                disabled
                                            >
                                            </s-select-client>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="auto" hidden> 
                                            <s-select-brands                                                
                                                clearable
                                                label="Marcas"
                                                v-model="CbdID"
                                                ref="SelectBrands"
                                                :cumID="CumID"
                                                disabled
                                                full>
                                            </s-select-brands>                                    
                                        </v-col>                            
                                        <v-col cols="auto">
                                            <v-btn 
                                                class="mt-2"
                                                color="primary"
                                                @click="save()">Guardar</v-btn>
                                        </v-col>
                                    </v-row>     
                                </v-col>
                            </v-row>                          
                            
                        </v-card-text>
                        <v-card-actions>
                                                  
                        </v-card-actions>
                    </v-card>
                </div> 
            </div>            
            <div class="row">
                <v-col cols="12">
                    <s-toolbar
                        dark
                        :color="'#BAB6B5'"
                        class="mr-4 ml-2"
                        style="display: flex"
                        print
                        @print="printQR()"
                    >
                    <v-btn 
                        icon
                        fab 
                        color="grey-lighten-4"
                        @click="itemGroupPallets()"
                        x-small >
                        <v-icon>
                             fa-boxes
                        </v-icon>
                    </v-btn>     
                    </s-toolbar>
                </v-col >
            </div>
            <v-row justify="center">
                <v-col cols="4">
                    <s-date label="Fecha Inicio"
                        v-model="itemDateBegin"
                        @input="searchitemsSend($event)"
                        >
                    </s-date>
                </v-col>
                <v-col cols="4">
                    <s-date label="Fecha Fin"
                        v-model="itemDateEnd"
                        @input="searchitemsSend($event)"
                        >
                    </s-date>
                </v-col>
            </v-row>
                
            <v-row justify="center">
                <div class="col">
                    <v-data-table
                        :headers="headersPallet"
                        :items="itemsPallet"
                        dense  
                        @dblclick:row="dobleClick"
                        :items-per-page="5"
                        show-select
                        @click:row="rowClickSend"
                        disable-sort
                        v-model="selected"
                        item-key="PapID"
                        group-by="PalletGroupID"
                    >
                    <!-- group-by="PalletGroupID" -->
                        <template v-slot:item.PalletGroupID="{ item }">
                            <v-chip
                            class="ma-2"
                            color="primary"
                            text-color="white"
                            v-if="item.isGroup == 1"
                            >
                            {{ item.PalletGroupID }}
                            <v-icon right style="margin: 0px 0px 0px 8px;">
                                fa-boxes
                            </v-icon>
                            
                            </v-chip>
                        </template>
                        <template v-slot:item.details="{ item }">
							<v-btn
								x-small
								fab
								style="margin: 6px 6px 6px 6px; background-color: #dd3b62; color: white;"
								@click="deleteParihuela(item)">
								<v-icon
                                    style="font-size: 16px !important">
                                    fas fa-times
                                </v-icon>
							</v-btn>
						</template>
                    </v-data-table>
                </div>
            </v-row>          
           
        </v-card>
        <v-row justify="center" cols="12" style="display: none">
            <v-col cols="12" v-for="(item, index) in selected" :key="index">
                <qr-code
                    :text="
                        item.PalletGroupID + ''
                        "
                    error-level="H"
                    :size="180"
                    hidden
                >
                </qr-code
                ><br />
            </v-col>
        </v-row>
    </diV>
</template>

<script>
    import _sFrzPackingArmedPallet from "@/services/FrozenProduction/FrzPackingArmedPallet.js"

    import _sFrzPackingArmedBox from "@/services/FrozenProduction/FrzPackingArmedBox.js";

    import qrCode from "vue-qr-generator";
    import SSelectClient from "@/components/FrozenProduction/Maturation/SSelectClienteMaturation";
    import SSelectBrands from "@/components/FrozenProduction/Chamber/SSelectBrandChamber";

    import _sFrzTypeExitCategoryService from "@/services/FrozenProduction/FrzTypeExitCategory.js"

  

    export default{
        components: {SSelectBrands, SSelectClient, qrCode},

        data:() =>({
            itemsLineProcess: [],
            objLineOut: {},
            itemObj:{

            },

            
            headersPalet:[
                { text: "ID", value:"Line"},
                { text: "Cliente", value: "CumNombre" },   
                { text:"Marca", value :"CbdBrans"},      
                { text: "N° Cajas", value: "TotalBox", width: 80 },   				
                { text: "Peso Total", value: "TotalBagWeight" },   
                { text: "Trazabilidad", value: "TraceabilityID"  },   	
                { text: "Empaque", value: "TypePackingName" }, 
                { text: "Corte", value: "TypeCutName"}, 
                { text: "Cultivo", value: "TypeCultiveName"  },  
                { text: "Var.", value: "VrtDescription"  }, 
                       
                // { text: "Tipo Producción", value: "TypeProductionName" }, 	
                // { text: "Variante", value: "TypeVariantName" },
                { text: "E. Final", value: "StateFinalName" },  
                { text: "Tipo Observación", value: "Observation"  },  			
            ],
            itemspalletsBox: [],
            selectItemsBox: [],
            ArmedItemsBox: [],
            deleteItemsBox: [],
            headersBoxSelected: [
                { text: "N°", value: "Index", width: 20 },
                { text: "Cliente", value: "CumNombre" },   
                { text: "Marca", value: "CbdBrans" },
                { text: "N° Cajas", value: "TotalBox" },   				
                { text: "Peso Total", value: "TotalBagWeight" },   
                { text: "ID trazabilidad", value: "TraceabilityID" },   	
                { text: "Empaque", value: "TypePackingName" },
                { text: "Eliminar", value: "remove" },
            ],
            NumberBoxes: 0,
            WeightNetBoxes: 0,
            headersPallet: [
                // { text: "Qr", value: "ImgQr", width: 20 }, 
                { text: "Pallets Agrupados", value: "PalletGroupID" },
                { text: "Hora de Creacion" , value: "HourBegin"},
                // { text: "ID", value: "PapID", width: 30 },   
                { text: "Cliente", value: "CumNombre" },   
                { text: "Marca", value: "CbdBrans" },
                { text: "Variedad", value: "VrtDescription" },
                { text: "Corte", value: "TypeCutName" },
                { text: "Presentacion", value: "TypePresentationName" },   
                { text: "N° Cajas", value: "NumberBoxes" },  
                { text: "E. Final", value: "StateFinalString" },  
                { text: "Cultivo", value: "TypeCultiveName" },
                { text: "T. Conge.", value: "CecDescription"},     
                { text: "Peso Neto", value: "WeightNetBoxes" },                            
                      
                // { text: "Tipo Producción", value: "TypeProductionString" }, 	
                // { text: "Variante", value: "TypeVariantString" },  
           
                { text: "Observación" , value: "Observation" },
                { text: "Eliminar", value: "details" },	
            ],
            itemsPallet:[]  ,
            itemrowPallet: {},            
            selected: [],
            codeGen: [],
            executeQR: false,
           
            itemDateBegin :"",
            itemDateEnd: "",
            itemsSend :[],
            itemsDetail:[],
            isRepacking:0,
            CumID: 0,
            CbdID: 0,           
            CampaignArea: [],
            SelectCampaign: 0,
            ItemsExitCategories: [],
            CecID: [],
            delete: false,
            CbdIDS: 0,
            PalletsGroup: []
        }),
        methods:{
            TipoCongelamiento()
            {
                let searchCecID = this.CecID.toString();
                
                _sFrzPackingArmedBox.list({CecIDs : searchCecID}, this.$fun.getUserID())
                .then(r =>{
                    if(r.status == 200)
                    {
                        r.data.forEach((element, index) => {
                            element.Line = index + 1;     
                            element.TotalBoxOld = element.TotalBox;                      
                        });                                  
                          
                        this.itemspalletsBox = r.data;                         
                    }
                });
            },
            getCampaignArea()
            {
                let TypeArea = 4;//id de area camara
                _sFrzPackingArmedPallet.getCampaignArea({ AreID: TypeArea}, this.$fun.getUserID())
                .then(r => {
                    if(r.status == 200)
                    {
                        this.CampaignArea = r.data;
                        this.SelectCampaign = this.CampaignArea[0];
                        
                    }
                })
            },
            initialize()
            {
                _sFrzTypeExitCategoryService.listexitcategoria({}, this.$fun.getUserID()).then(r =>{
                    if(r.status == 200)
                    {
                        this.ItemsExitCategories = r.data;                    
                    }
                });    
                let searchCecID = this.CecID.toString();
                _sFrzPackingArmedBox.list({CecIDs : searchCecID}, this.$fun.getUserID())
                .then(r =>{
                    if(r.status == 200)
                    {
                        r.data.forEach((element, index) => {
                            element.Line = index + 1;
                            element.disabled = 1;
                            element.TotalBoxOld = element.TotalBox;
                        });        
                        
                        this.itemspalletsBox = r.data; 
                        
                              
                    }
                });
                this.getListpalletArmed();
               
                
            },
            deleteParihuela(item)
            {
                
                this.$fun.alert("¿Esta seguro de guardar?", "question").then((r) => {
                    item.SecStatus = 0;
                    item.palletDetails.forEach((element) => {
                        element.SecStatus = 0;
                    });
                    
                    if (r.value) {
                        _sFrzPackingArmedPallet.save(item, this.$fun.getUserID()).then(r => {
                            if(r.status == 200){
                                this.itemspalletsBox = [];
                                this.ArmedItemsBox = [];
                                this.itemsPallet = [];
                                this.selectItemsBox = [];
                                this.initialize();                                
                                this.executeQR = true;
                                this.CumID = 0;
                                this.getPalletSend();
                            }
                        });
                    }
                });  
            },
            getListpalletArmed(){
                if(this.itemDateBegin == "" || this.itemDateEnd == ""){
					return;
				}
               
                _sFrzPackingArmedPallet.list({DateBegin : this.itemDateBegin, DateEnd: this.itemDateEnd }, this.$fun.getUserID())
                .then(r =>{
                    if(r.status == 200)
                    {
                        this.itemsPallet = r.data;
                        
                        this.itemsPallet.forEach(element => {
                            let cont = 0;
                            element.palletDetails.forEach( i => {
                                if(i.Observation != null)
                                {
                                    element.Observation = i.Observation;
                                }
                                element.CecDescription = i.CecDescription;
                                
                                if(cont == 0 || element.palletDetails[cont - 1].TypeVariantName != i.TypeVariantName)
                                {
                                    if(cont == 0 )
                                    {
                                        element.TypeVariantString = i.TypeVariantName;
                                    }else{
                                        element.TypeVariantString += '/' + i.TypeVariantName;
                                    }
                                    
                                }

                                if(cont == 0 || element.palletDetails[cont - 1].TypeProductionName != i.TypeProductionName)
                                {
                                    if(cont == 0 )
                                    {
                                        element.TypeProductionString = i.TypeProductionName;
                                    }else{
                                        element.TypeProductionString += '/' + i.TypeProductionName;
                                    }
                                    
                                }

                                if(cont == 0 || element.palletDetails[cont - 1].StateFinalName != i.StateFinalName)
                                {
                                    if(cont == 0 )
                                    {
                                        element.StateFinalString = i.StateFinalName;
                                    }else{
                                        element.StateFinalString += '/' + i.StateFinalName;
                                    }
                                    
                                }
                                
                                cont++;
                            })
                        });
                    }
                });
            },

            save()
            {
                
                if(this.ArmedItemsBox.length == 0){                    
                    this.$fun.alert("Seleccione Cajas para armar", "warning");
                    return;
                }  
                
                if(this.CumID === 0 || this.CumID == undefined) 
                {
                    this.$fun.alert("Seleccione cliente", "warning");
                    return;   
                }
                if(this.CbdID == 0  || this.CbdID == undefined)
                {
                    this.$fun.alert("Seleccione marca", "warning");
                    return; 
                }
                // let PalletsGroup = [];
                this.itemObj.NumberBoxes = this.NumberBoxes;   
                this.itemObj.WeightNetBoxes = this.WeightNetBoxes;
                this.itemObj.UsrUpdate = this.$fun.getUserID();
                this.itemObj.UsrCreate = this.$fun.getUserID();
                this.itemObj.isRepacking = this.ArmedItemsBox[0].isRepacking;              

                const deleteItemsBox = this.deleteItemsBox || [];
                this.PalletsGroup = [...this.ArmedItemsBox, ...deleteItemsBox];

                this.PalletsGroup.sort((a,b) => a.Line - b.Line);
                
                let sumsByTrazabilidad  = {};
                let resultados = [];
                this.PalletsGroup.forEach((pallet) => {
                   
                    if(pallet.Observation != null)
                    {
                        pallet.PxdIDs = [];
                        pallet.TotalBoxs = [];
                        pallet.TotalBagWeights = [];
                        pallet.PxdIDs.push(pallet.PxdID);
                        pallet.TotalBoxs.push(pallet.TotalBox);
                        pallet.TotalBagWeights.push(pallet.TotalBagWeight);
                        resultados.push(pallet);
                    }
                    else 
                    {
                        if(!sumsByTrazabilidad[pallet.TraceabilityID])
                        {                         
                            sumsByTrazabilidad[pallet.TraceabilityID] = { ...pallet,
                                PxdIDs: [pallet.PxdID],
                                TotalBoxs: [pallet.TotalBox],
                                TotalBagWeights: [pallet.TotalBagWeight]
                            };
                        
                        }else{
                            
                            sumsByTrazabilidad[pallet.TraceabilityID].TotalBox += parseInt(pallet.TotalBox);
                            sumsByTrazabilidad[pallet.TraceabilityID].TotalBagWeight += parseFloat(pallet.TotalBagWeight);
                            sumsByTrazabilidad[pallet.TraceabilityID].PxdIDs.push(pallet.PxdID);
                            sumsByTrazabilidad[pallet.TraceabilityID].TotalBoxs.push(pallet.TotalBox);
                            sumsByTrazabilidad[pallet.TraceabilityID].TotalBagWeights.push(pallet.TotalBagWeight);
                        }
                    }
                });

                // resultado = Object.values(sumsByTrazabilidad);
                Object.keys(sumsByTrazabilidad).forEach(clave => {
                    resultados.push(sumsByTrazabilidad[clave]);
                });

                resultados.forEach(result => {
                    
                    result.PxdIDs = result.PxdIDs.join(",");
                    result.TotalBoxs = result.TotalBoxs.join(",");
                    result.TotalBagWeights = result.TotalBagWeights.join(",");
                })
                
                this.itemObj.palletDetails = resultados;
                this.itemObj.TypeCultive = this.itemObj.palletDetails[0].TypeCultive;
                this.itemObj.VrtID = this.itemObj.palletDetails[0].VrtID;
                this.itemObj.TypePacking = this.itemObj.palletDetails[0].TypePacking;
                this.itemObj.CumID = this.itemObj.palletDetails[0].CumID;
                
             
                this.itemObj.CmaID = this.SelectCampaign.CmaID;
                
                // this.itemObj.palletDetails[0].RcfIDs = this.itemObj.palletDetails[0].boxDetails[0].RcfIDs;
                this.itemObj.palletDetails.forEach((element, index ) => {
                    // if(element.RcfIDs != null && element.RcfIDs != undefined)
                    // {
                        console.log('items', element.boxDetails[0]);
                        element.TypeCut = element.boxDetails[0].TypeCut;
                        element.CecID = element.boxDetails[0].CecID;
                        element.RcfIDs = element.boxDetails[0].RcfIDs;
                        element.TypeProduction = element.boxDetails[0].TypeProduction;
                        element.TypeVariant = element.boxDetails[0].TypeVariant;
                        element.StateFinal = element.boxDetails[0].StateFinal;
                        element.PipID = element.boxDetails[0].PipID;

                        element.TypeObservation = element.boxDetails[0].TypeObservation;
                        element.TypeGroup = element.boxDetails[0].TypeGroup;
                        element.TypeSubGroup = element.boxDetails[0].TypeSubGroup;
                    // }
                });

                this.itemObj.TypeCut = this.itemObj.palletDetails[0].TypeCut;
                this.itemObj.CbdID = this.CbdIDS;              
                this.itemObj.CumID = this.CumID;               
                
                this.itemObj.palletDetails.forEach( r => {
                    r.TotalBagWeight = r.TotalBox * r.boxDetails[0].BagWeight;
                })

                let validarBox = false;
                this.itemObj.palletDetails.forEach( r => {
                    
                    if(isNaN(parseInt(r.TotalBox)))
                    {
                        validarBox = true;
                        return;                     
                    }                    
                });
                
                if(validarBox)
                {
                    this.$fun.alert("Cada item debe tener un numero de cajas valido", "warning");
                    return;   
                }                
                
                this.$fun.alert("¿Esta seguro de guardar?", "question").then((r) => {

                    if (r.value) {
                        _sFrzPackingArmedPallet.save(this.itemObj, this.$fun.getUserID()).then(r => {
                            if(r.status == 200){
                                this.itemspalletsBox = [];
                                this.ArmedItemsBox = [];
                                this.itemsPallet = [];
                                this.selectItemsBox = [];
                                this.initialize();                                
                                this.executeQR = true;
                                this.CumID = 0;
                                this.getPalletSend();
                                // this.printQR();
                            }
                        });
                    }
                });               
            },

            clickDelete( item ){
                
                this.ArmedItemsBox =
                    this.ArmedItemsBox.filter(
                    (x) => x.Line != item.Line
                    );

                // item.SecStatus = 0;
                // if(item.Line != undefined){
                //     this.deleteItemsBox.push(item);
                // }
                
                this.delete = true;
                this.CalcularTotales();
            },
            CalcularTotales(valueNew, valueold)
            {  
                let detail = this.ArmedItemsBox;
                           
                this.NumberBoxes = 0;
                this.WeightNetBoxes = 0;
                this.isRepacking = 0;
                detail.forEach(element => {                    
                    this.NumberBoxes = this.NumberBoxes + parseFloat(element.TotalBox);
                    element.TotalBagWeight = element.TotalBox * element.boxDetails[0].BagWeight * element.boxDetails[0].BoxBag;                   
                    this.WeightNetBoxes = this.NumberBoxes * element.boxDetails[0].BagWeight * element.boxDetails[0].BoxBag;
                })   
               
            },
            getPalletSend(){
				if(this.itemDateBegin == "" || this.itemDateEnd == ""){
					return;
				}
				_sFrzPackingArmedPallet
					.getPalletSend(
						{ 
							DateBegin : this.itemDateBegin,
							DateEnd : this.itemDateEnd
						}
						, this.$fun.getUserID())
					    .then(r => {
						this.itemsSend = r.data;

						

						if(this.executeQR){
							if(this.itemsSend.length > 0){
								let ob = this.itemsSend[0];
								this.selected = [ob];
								this.rowClickSend(ob, ob);

								if(this.selected.length > 0) {

									_sFrzPackingArmedPallet.GetQr({PapID : this.selected[0].PapID}, this.$fun.getUserID()).then(resp => {
										if (resp.status == 200) {
											this.itemsDetail = resp.data;
                                            this.selected[0].PalletGroupID = this.itemsDetail[0].PalletGroupID;
											this.printQR();
											
											this.executeQR = false
											
										}
									});
								}
								
							}
						}
					});
			},
            rowSelected(envent, row)
            {
                
                this.selectItemsBox = [];
                let obj = row.item;
               
                
                obj.palletDetails.forEach(element => {
                    this.selectItemsBox.push(element);
                });
                
            },
            rowClickSend: function(item, row) {
				this.selected = [item];				
                
                if(this.selected.length > 0) 
                {                    
                    _sFrzPackingArmedPallet.GetQr({PapID : this.selected[0].PapID}, this.$fun.getUserID()).then(resp => {
                        if (resp.status == 200) {
                            this.selected = resp.data;
                            
                        }
                    });                   
                }
			},
            UpdateWeight(item)
            {               
                
                item.TotalBox = parseInt(item.TotalBox);
                this.CalcularTotales();               
            },
            itemGroupPallets(){
                
                this.codeGroupPallet();
                if(this.selected.length <= 0){
                    this.$fun.alert("Seleccione pallet", "warning");
                    return;
                }
                if(this.selected.length == 1){
                    this.$fun.alert("Seleccione mas de un Item para agrupar", "warning");
                    return;
                }

                this.$fun.alert("¿Desea Agrupar Pallet ?", "question")
                .then((r) => {

                    if (r.value) {
                        let list = [];
                        this.selected.forEach(element => {
                            list.push(element.PapID);
                        });
                        // 
                        let items = {};
                    
                        items.List = list;                  
                        items.UsrUpdateID = this.$fun.getUserID();                          
                        items.UsrCreateID = this.$fun.getUserID(); 

                        let SumNumberBoxes = 0;
                        let WeightNetBoxes = 0;
                        let PalletGroupIDDetails ='';
                        let PalletDtl = [];
                        this.selected.forEach(element => {
                            SumNumberBoxes += (element.NumberBoxes);
                            WeightNetBoxes += (element.WeightNetBoxes);
                            PalletGroupIDDetails += (element.PalletGroupID)+',';
                        });
                        this.selected.forEach(element => {
                                element.palletDetails.forEach(x =>{
                                    PalletDtl.push(x)
                                })                            
                        });

                        items.NumberBoxes = SumNumberBoxes;
                        items.WeightNetBoxes = WeightNetBoxes;
                        items.PalletGroupIDDetail = PalletGroupIDDetails;
                        items.CumID = this.selected[0].CumID;
                        items.UsrCreate = this.$fun.getUserID();
                        items.palletDetails = PalletDtl;
                        items.TypePacking = this.selected[0].TypePacking;
                        items.TypeCultive = this.selected[0].TypeCultive;
                        items.isRepacking = items.palletDetails[0].isRepacking;
                        items.typeArmed = items.palletDetails[0].typeArmed;
                        items.VrtID = this.selected[0].VrtID;
                        items.PklByp = items.palletDetails[0].PklByp;
                        items.TypeCut = items.palletDetails[0].TypeCut;
                        items.CbdID = this.selected[0].CbdID;
                        items.CmaID = this.selected[0].CmaID;

                        this.selected = [];
                        
                        _sFrzPackingArmedPallet.createPalletChamber(items, this.$fun.getUserID())
                        .then((r) => {
                            if (r.status == 200) {
                                this.$fun.alert("Se creo correctamente", "success");
                                    _sFrzPackingArmedPallet.save(items, this.$fun.getUserID()).then(r => {
                                        if(r.status == 200){
                                            this.initialize();
                                            this.executeQR = true
                                            this.getPalletSend();
                                        }
                                    });                                
                            }
                        })
                    }
                });
            },
            codeGroupPallet(){
                
                 _sFrzPackingArmedPallet.generateCod({},this.$fun.getUserID())
                .then((r) => {
                    if (r.status == 200)
                    {                        
                        this.codeGen = r.data[0].PalletGroupID;                        
                    }
                })
            },
            searchitemsSend(date){		
				
                this.getListpalletArmed();
			},
                
            dobleClick(click, row){
                
                // let obj = row.item;
                // this.itemObj = obj;
                // let details = obj.palletDetails;
                // details.forEach((element, index) => {
                //     element.Index = index + 1;
                // });
                // this.ArmedItemsBox = details;
            },
            printQR() {   
                    if(this.itemsDetail.length > 0 && this.itemsDetail[0].PalletGroupID != null){
                            
                    var imgAll = document.querySelector("img");                    
                    
                    var Pagelink = "about:blank";
                    var pwa = window.open(Pagelink, "_new");
                    pwa.document.open();
                    pwa.document.write(
                        "<html><head><scri" +
						"pt>function step1(){\n" +
						"setTimeout('step2()', 10);}\n" +
						"function step2(){window.print();window.close()}\n" +
						"</scri" +
						"pt><style  media='print'> body{font-family: arial} table, th, td {border: 1px solid black;border-collapse: collapse;padding:2px;font-size:9pt;}" + 
						" @media print  { @page { margin-left: 0.5cm; {} </style></head><body onload='step1()'> \n" +
						"<div class='col col-12 justify-center'>",
                    );
                    pwa.document.write(
                        "<div class='col col-6' style='margin: 20px 20px 20px 20px; position:absolute;'><img alt='Scan me!' style='display: block;'  src='" +
                            imgAll.currentSrc +
                            "' />",
                    );
                        
                    pwa.document.write("<b style='font-size: 35px!important; z-index: 2;margin-top: 40px;border-radius: 20px;'>ID: " + 
                        this.itemsDetail[0].PalletGroupID
                        
                        +"</b>"); 


                    pwa.document.write("</div><div style='margin: 30px 0px 0px 410px;'><table colspan='1' border='1'>");
                    pwa.document.write(
                    "<tr><td colspan='2' style='text-align:center;'><b>Sunshine Camaras</b></td>" + 
                            
                            "</tr>"+
                                "<tr><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AP-FR-213<br>Paleticket de identificación de pallet</td><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;VERSION:04" +
                            "<br>F. creación:01/07/2024" +
                            "<br>F. última revisión:01/07/2024</td></tr>" +		
                        "<tr><td>Cliente</td><td>" + this.itemsDetail[0].CumNombre + "</td></tr>"+
                        "<tr><td>Marca</td><td>" + this.itemsDetail[0].CbdBrans + "</td></tr>"+
                        "<tr><td>Materia Prima</td><td>" + this.itemsDetail[0].TypeCultiveName + "</td></tr>"+
                        "<tr><td>Variedad</td><td>" + this.itemsDetail[0].VrtDescription + "</td></tr>"+
                        "<tr><td>Trazabilidad</td><td>" + this.itemsDetail[0].TraceabilityIDs + "</td></tr>"+
                        "<tr><td>Corte</td><td>" + this.itemsDetail[0].TypeCutName + "</td></tr>"+
                        "<tr><td>Presentacion</td><td>" + this.itemsDetail[0].TypePresentationName + "</td></tr>" +
                        "<tr><td>Cajas</td><td>" + this.itemsDetail[0].NumberBoxes + " </td></tr>" + 
                        "<tr><td>Peso (Kg)</td><td>" + this.itemsDetail[0].WeightNetBoxes + " KG.</td></tr>"+
                        "<tr><td>Estado Final</td><td>" + this.itemsDetail[0].StateFinalName + " </td></tr>"							
							
					);
					pwa.document.write("</table></div>");
                    pwa.document.write("</div></body></html>");
                    pwa.document.close();
               
                }
			},           
            SelectClient(select)
            {
                if(select.length > 0)
                {         
                       
                    this.CumID = select[0].CumID;
                    this.CbdIDS = select[0].CbdID;
                }else{
                    this.CumID = 0;
                    this.CbdID = 0;
                }
            }
        },
        mounted()
        {
            this.initialize();
            this.getCampaignArea();
        },
        
        watch:{
            selectItemsBox: { handler(valueNew, valueold)
                {  
                    this.SelectClient(valueNew);
                    if(!this.delete)
                    {                    
                        let add = true;
                        valueNew.forEach((element, index) => 
                        {                        
                            element.Index = index + 1;
                            element.disabled = 0;
                        
                            if(parseInt(element.TotalBox) <= element.TotalBoxOld)
                            {
                                add = true;
                            }
                            else
                            {
                                this.$fun.alert("El numero de cajas no puede ser 0 O mayor que " + element.TotalBoxOld, "error");
                                add = false;
                                if(parseInt(element.TotalBox) > element.TotalBoxOld)
                                {
                                    element.TotalBox = element.TotalBoxOld;
                                }
                            }
                        });
                    
                        if(add)
                        {
                            this.ArmedItemsBox = [...valueNew];
                            this.CalcularTotales();
                        }   
                    }  
                    this.delete = false;               
                },
                deep: true
            },
            CbdIDS: { handler(valueNew, valueold)
                {
                    this.CbdID = valueNew;
                    
                }
            }
        },
          
        
    }
</script>